import React from "react"
import { Link, graphql } from "gatsby"

import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const querymp = graphql`
  query PolicyPageQuery($slug: String!) {
    allStrapiPolicyPage {
      edges {
        node {
          title
          slug
        }
      }
    }
    strapiPolicyPage(slug: { eq: $slug }) {
      slug
      hero {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 80
              breakpoints: [768, 1024, 1280]
              layout: FULL_WIDTH
            )
          }
        }
      }
      description
      content
      title
    }
  }
`

const PolicyPage = ({ data }) => {
  const policyPage = data.strapiPolicyPage

  function markupPolicyPageContent() {
    return { __html: policyPage.content }
  }

  return (
    <Layout>
      <Seo
        title={policyPage.title + " | Vacational Studies"}
        description={policyPage.description}
      />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Submenu</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  {data.allStrapiPolicyPage.edges
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((policyPage, i) => {
                      return (
                        <li
                          className="subnav__item"
                          key={`page__${policyPage.node.slug}`}
                        >
                          <Link
                            className="subnav__link"
                            to={`/${policyPage.node.slug}`}
                          >
                            {policyPage.node.title}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div id="hero" className="container max-width-adaptive-lg margin-top-sm">
        <section
          className="bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${getSrc(
              policyPage.hero.localFile.childImageSharp.gatsbyImageData
            )})`,
          }}
        >
          <div className="padding-y-xxxl"></div>
        </section>
      </div>

      <div
        id="content"
        className="container max-width-adaptive-sm padding-top-xl padding-bottom-xxl position-relative z-index-1"
      >
        <article className="article text-component">
          <h1 className="text-xxxl">{policyPage.title}</h1>
          <p className="text-md color-contrast-medium">
            {policyPage.description}
          </p>
          <div dangerouslySetInnerHTML={markupPolicyPageContent()} />
        </article>
      </div>
    </Layout>
  )
}

export default PolicyPage
